import provinces from 'china-division/dist/provinces.json';
import cities from 'china-division/dist/cities.json';
import areas from 'china-division/dist/areas.json';

// 选择到区
const options = provinces.map(province => {
  return {
    label: province.name,
    value: province.code,
    children: cities.filter(city=>city.provinceCode == province.code).map(city => {
      return {
        label: city.name,
        value: city.code,
        children: areas.filter(area=>area.cityCode == city.code).map(area => {
          return {
            label: area.name,
            value: area.code,
          }
        }),
      }
    }),
  }
});
// console.log(options);
export default options;