<template >
  <div class="table-container">
    <div class="table-tabs">
      <a-radio-group size="large" button-style="solid" v-model:value="filter.tag_status" @change="handleSearch">
        <a-radio-button value="0">未标注</a-radio-button>
        <a-radio-button value="1">已标注</a-radio-button>
      </a-radio-group>
    </div>
    <div class="table-toolbar">
      <div class="title">
        <div class="text">
          <span>政策标注</span>
          <!-- <span class="desc">(一级)</span> -->
        </div>
      </div>
      <div class="filters">
        <a-cascader class="filter" placeholder="区域" :options="areas" v-model:value="ssqy"></a-cascader>
        <a-select class="filter" allowClear placeholder="选择部门" v-model:value="filter.dept_id"
          :options="deptList"></a-select>
        <a-select v-if="filter.tag_status == 1" class="filter" allowClear placeholder="政策类型" v-model:value="filter.zclx"
          :options="zclxList"></a-select>
          <a-select class="filter" allowClear placeholder="录入方式" v-model:value="filter.is_manual"
          :options="manual"></a-select>
        <a-input class="filter" placeholder="标题" v-model:value="filter.title"></a-input>
        <a-button class="filter-btn" type="primary" @click="handleSearch">搜索</a-button>
        <a-button class="filter-btn" type="link" @click="handleSearchClear">清空</a-button>
        <a-button class="filter-btn" type="primary" @click="upAdd">添加政策</a-button>
      </div>
      <!-- <div class="opts">
        <a-button class="opt-btn" size="large" type="link" @click="toCreate">新建</a-button>
      </div> -->
    </div>
    <div class="table">
      <a-table size="middle" :columns="columnsFilter" :row-key="record => record.id" :data-source="dataSource"
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex == 'icon'">
            <div>
              <FjList :fjlist="record.icon" />
            </div>
          </template>
          <template v-else-if="column.dataIndex == 'title'">
            <div class="data-title" >
              <a-tooltip v-if="!record.is_manual" @click="toUrl(record)" placement="topLeft" :title="record.title" :overlayStyle="{ width: 500 }" :color="'geekblue'">
                <span>{{ record.title }}</span>
              </a-tooltip>
              <a-tooltip v-else @click="downloadFile(record)" placement="topLeft" :title="record.title" :overlayStyle="{ width: 500 }" :color="'geekblue'">
                <span>{{ record.title }}</span>
              </a-tooltip>
            </div>
          </template>
          <template v-if="column.dataIndex == 'area_code'">
            <div>
              <span>{{ record.sheng }}/{{ record.shi }}/{{ record.qu }}</span>
            </div>
          </template>
          <template v-if="column.dataIndex == 'dept_id'">
            <div>
              <span>{{ record.dept_info?.dept_name }}</span>
            </div>
          </template>
          <template v-else-if="column.dataIndex == 'userinfo'">
            <div>
              <span>{{ record.userid != 0 ? (record.userinfo?.name) : '' }}</span>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'operation'">
            <div class="operations">
              <a-button class="btn" type="info" size="small" @click="setLabel(record)">{{ filter.tag_status == '0' ?
                '标注' : '编辑' }}</a-button>
              <!-- <a-button class="btn" type="primary" size="small" @click="showZy(record)">摘要</a-button> -->
              <a-button class="btn" v-if="filter.status == '1'" type="danger" size="small"
                @click="del(record)">删除</a-button>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <Edit ref="editRef" @close="current = null;" :current="current" @refreshList="handleSearch" />

    <Uparticle ref="upaddRef" @close="current = null;" :current="current" @refreshList="handleSearch" />

    <a-modal v-model:visible="visible" :footer="null" title="文件下载" @ok="handleOk">
        <div v-for="(item,index) in article_file" :key="index">
          <p><a :href="item">{{ file_name[index] }}</a></p>
        </div>
        
      </a-modal>

    <a-modal :loading="flags.confirmLoading" v-model:visible="flags.zyVisible" title="提交摘要" @ok="handleOk">
      <a-form layout="vertical">
        <a-form-item label="申报条件">
          <a-textarea v-model:value="currentZy.sbtj" placeholder="请输入" :auto-size="{ minRows: 3 }" />
        </a-form-item>
        <a-form-item label="扶持标准">
          <a-textarea v-model:value="currentZy.fcbz" placeholder="请输入" :auto-size="{ minRows: 3 }" />
        </a-form-item>
        <a-form-item label="申报材料">
          <a-textarea v-model:value="currentZy.sbcl" placeholder="请输入" :auto-size="{ minRows: 3 }" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { usePagination } from 'vue-request';
import { computed, defineComponent } from 'vue';
import service from '@/service/service';
import { message, Modal } from 'ant-design-vue';
import FjList from '@/components/common/fjList.vue';
import options from '@/util/address-options';
import Edit from './Edit.vue';
import Uparticle from './Uparticle.vue';

export default defineComponent({
  components: {
    FjList,
    Edit,
    Uparticle
  },

  data() {
    return {
      currentTab: '',
      visible:0,
      ssqy: '',
      deptList: [],
      article_file:[],
      file_name:[],
      areas: options,
      zclxList:[{value: '申报通知',label: '申报通知',},{value: '立项批文',label: '立项批文',},{value: '奖励文件',label: '奖励文件',},{value: '信息资讯',label: '信息资讯',}],
      manual:[{value:0,label:"自动采集"},{value:1,label:"手动添加"}],
      filter: {
        ...this.initFilter,
        title: '',
        dept_id: undefined,
        zclx:undefined,
        //is_manual:-1,
      },
      current: null,
      flags: {
        zyVisible: false,
        confirmLoading: false,
      },

      currentZy: {
        sbtj: '',
        fcbz: '',
        sbcl: '',
        id: '',
      }
    }
  },
  created() {
    this.getOptions();
  },
  computed: {
    columns() {
      let temp = [
        {
          title: 'ID',
          dataIndex: 'id',
        },
        {
          title: '发布日期',
          dataIndex: 'release_time',
        },
        // {
        //   title: '区域',
        //   dataIndex: 'area_code',
        // },
        {
          title: '部门',
          dataIndex: 'dept_id',
        },
        {
          title: '标题',
          dataIndex: 'title',
          width: 500,
        },
        {
          title: '政策类型',
          dataIndex: 'zclx',
        },
        {
          title: '截止日期',
          dataIndex: 'jzrq',
        },
        {
          title: '操作人',
          dataIndex: 'zl_user',
        },
        {
          title: '操作',
          dataIndex: 'operation'
        }
      ];
      if (this.filter.tag_status == '0') {
        return temp.filter(f => f.dataIndex != 'zl_user');
      }
      else {
        return temp;
      }
    },
    columnsFilter() {
      if (this.filter.tag_status == '0') {
        return this.columns.filter(f => !['zclx', 'jzrq'].includes(f.dataIndex))
      }
      else return this.columns;
    }
  },
  methods: {
    async handleOk() {
      if (!this.currentZy.sbtj || !this.currentZy.fcbz || !this.currentZy.sbcl) {
        message.error('请输入完整')
        return;
      }
      this.flags.confirmLoading = true;
      const { code, msg } = await service.submit_article_info({ ...this.currentZy });
      this.flags.confirmLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.flags.zyVisible = false;
        this.handleSearch();
        return true; 
      }
      else {
        message.error(msg || '失败');
        return false;
      }
    },
    downloadFile(item){
      this.visible = 1
      this.article_file = JSON.parse(item.article_file)
      this.file_name = JSON.parse(item.file_name)
      //console.log(item)
    },
    showZy(item) {
      // console.log(item);

      this.currentZy = {
        sbtj: item.sbtj || '',
        fcbz: item.fcbz || '',
        sbcl: item.sbcl || '',
        id: item.id,
      };
      this.flags.zyVisible = true;
      // console.log(this.flags.zyVisible);
    },
    async getOptions() {
      const { data: depts } = await service.get_dept_lists({ limit: 1000 });
      this.deptList = depts.map((m) => ({ label: m.dept_name, value: Number(m.id) }));
    },
    handleTableChange(pag, filter, sorter) {
      this.run({
        limit: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order ? (sorter.order.includes('asc') ? 'asc' : 'desc') : '',
        ...this.filter,
      });
    },
    // 筛选
    handleSearch() {
      this.pagination.current = 1;
      let ssqyTemp = {};
      if (this.ssqy?.length) {
        ssqyTemp['sheng'] = this.ssqy[0]?.split('/')[1] || '';
        ssqyTemp['shi'] = this.ssqy[1]?.split('/')[1] || '';
        ssqyTemp['qu'] = this.ssqy[2]?.split('/')[1] || '';
      }
      this.run({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.filter,
        ...ssqyTemp,
      })
    },
    handleSearchClear() {
      this.pagination.current = 1;
      this.filter = {
        ...this.filter,
        title: '',
        dept_id: '',
      }
      this.ssqy = [];
      this.run({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.filter,
      })
    },
    setLabel(item) {
      this.current = item;
      this.$refs['editRef'].onShow();
    },

    upAdd(item) {
      this.current = item;
      this.$refs['upaddRef'].onShow();
    },

    del(item) {
      Modal.confirm({
        title: '警告',
        content: `确认删除[${item.title}]?`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          const { code, msg } = await service.delete_article({ id: item.id });
          if (code && code == '1') {
            message.success(msg || '成功');
            this.handleSearch();
          }
          else {
            message.error(msg || '失败');
          }
        }
      });
    },
    rel(item) {
      Modal.confirm({
        title: '警告',
        content: `确认发布[${item.title}]?`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          const { code, msg } = await service.release_article({ id: item.id });
          if (code && code == '1') {
            message.success(msg || '成功');
            this.handleSearch();
          }
          else {
            message.error(msg || '失败');
          }
        }
      });
    },
    toCreate() {
      this.current = { pid: '0' }
      this.$refs['editRef'].onShow();
    },
    toUrl(item) {
      window.open(item.url)
    },
  },
  setup() {
    // 声明并调用一次数据拉取
    var initFilter = {
      tag_status: '0',
      status: '1',
    }
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = usePagination((params) => service.get_article_lists({ ...initFilter, ...params }), {
      formatResult: res => {
        res.data.total_num = res.total_number;
        return res.data;
      },
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'limit',
        totalKey: 'total_num',
      },
    });
    // 分页所需的属性
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
    }));

    // data用
    return {
      initFilter,
      dataSource,
      pagination,
      loading,

      run,
    };
  },
})
</script>
<style lang="less" scoped>
.table-container {
  margin: 24px 0;

  width: 1400px;

  .data-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 500px;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      color: #3399FF;
    }
  }
}
</style>