<template>
  <div>
    <a-drawer :title="`标注操作`" :width="1440" :maskClosable="false" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-descriptions :title="form.title">
        <a-descriptions-item>{{ form.release_time }}</a-descriptions-item>
        <a-descriptions-item>来源：{{ form.dept_info.dept_name }}</a-descriptions-item>
        <!-- <a-descriptions-item>{{ form.area_code }}</a-descriptions-item> -->
      </a-descriptions>
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="4">
            <a-form-item label="政策类型" name="zclx">
              <a-select :options="[{
                label: '申报通知',
                value: '申报通知'
              }, {
                label: '立项批文',
                value: '立项批文'
              },{
                label: '奖励文件',
                value: '奖励文件'
              }, {
                label: '信息资讯',
                value: '信息资讯'
              }]" v-model:value="form.zclx" placeholder="请选择">
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :span="4" v-if="form.zclx == '申报通知'">
            <a-form-item label="申报项目" name="declare_item">
              <a-select :options="sbxms" v-model:value="form.declare_item" placeholder="请选择">
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :span="4" v-if="form.zclx == '申报通知'">
            <a-form-item label="申报金额" name="declare_item">
              <a-input v-model:value="form.amount" placeholder="请输入">
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="4" v-if="form.zclx == '申报通知'">
            <a-form-item label="计算金额" name="computed_amount">
              <a-input v-model:value="form.computed_amount" placeholder="请输入">
              </a-input>
            </a-form-item>
          </a-col>
          <!-- <a-col :span="4" v-if="form.zclx == '申报通知'">
            <a-form-item label="项目类别" name="item">
              <a-select :options="items" v-model:value="form.item" placeholder="请选择">
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :span="4" v-if="form.zclx == '申报通知'">
            <a-form-item label="产业类别" name="trade">
              <a-tree-select v-model:value="form.trade" show-search style="width: 100%" :defaultValue="form.trade"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择" allow-clear multiple
                :field-names="{
                  children: 'children',
                  label: 'name',
                  value: 'id',
                }" :tree-data="trades" treeNodeFilterProp="title">
              </a-tree-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" v-if="form.zclx == '申报通知'">
            <a-form-item label="企业类型" name="ctype">
              <a-select :options="ctypes" v-model:value="form.ctype" mode="multiple"  placeholder="请选择">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" v-if="form.zclx == '申报通知'">
            <a-form-item label="开始日期" name="ksrq">
              <a-date-picker style="width:100%;" valueFormat="YYYY-MM-DD" v-model:value="form.ksrq" placeholder="截止日期" />
            </a-form-item>
          </a-col>
          <a-col :span="4" v-if="form.zclx == '申报通知'">
            <a-form-item label="截止日期" name="jzrq">
              <a-date-picker style="width:100%;" valueFormat="YYYY-MM-DD" v-model:value="form.jzrq" placeholder="截止日期" />
            </a-form-item>
          </a-col>
          <a-col :span="6" v-if="form.zclx == '申报通知'">
            <a-form-item label="政策覆盖" name="zcfg">
              <a-tree :fieldNames="{ title: 'label', key: 'value' }" v-model:checkedKeys="qyfgCheckedKeys" checkable
                :tree-data="areas">

              </a-tree>
            </a-form-item>
          </a-col>
        </a-row>

        <template v-if="form.zclx != '申报通知' && form.zclx">
          <a-form-item class="selectFormItem">
              <a-form-item label="政策摘要">
                <a-textarea v-model:value="form.description" placeholder="请输入" :auto-size="{ minRows: 5 }" />
              </a-form-item>
          </a-form-item>
        </template>

        <template v-if="form.zclx == '申报通知'">
          <a-form-item class="selectFormItem">
              <a-form-item label="申报条件">
                <a-textarea v-model:value="form.sbtj" placeholder="请输入" :auto-size="{ minRows: 3 }" />
              </a-form-item>
              <a-form-item label="扶持标准">
                <a-textarea v-model:value="form.fcbz" placeholder="请输入" :auto-size="{ minRows: 3 }" />
              </a-form-item>
              <a-form-item label="申报材料">
                <a-textarea v-model:value="form.sbcl" placeholder="请输入" :auto-size="{ minRows: 3 }" />
              </a-form-item>
          </a-form-item>
        </template>

        <template v-if="form.zclx == '申报通知'">
          <a-form-item class="selectFormItem" :label="fl.name" v-for="(fl, idx) in tagData" :key="idx">
            <a-row :gutter="16">
              <a-col :span="tag.type == '1' ? 4 : 5" v-for="(tag, tag_idx) in fl.tag_data" :key="tag_idx">
                <div class="checkbox-wrap">
                  <a-checkbox v-if="tag.type == '1'" v-model:checked="tag.selected">{{ tag.name }}</a-checkbox>
                  <template v-else-if="tag.type == '2'">
                    <a-checkbox :checked="tag.selected">{{ tag.name }}</a-checkbox>
                    <a-input @change="handleInput(tag, $event)" style="width:120px;" v-model:value="tag.value"
                      :suffix="tag.unit" />
                  </template>
                  <template v-else-if="tag.type == '3'">
                    <a-checkbox :checked="tag.selected">{{ tag.name }}</a-checkbox>
                    <a-input @change="handleInput(tag, $event)" style="width:120px;" v-model:value="tag.value"
                      :suffix="tag.unit" />
                  </template>
                </div>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item class="selectFormItem" label="财务指标">
            <a-descriptions bordered :column="1" class="customDescript">
              <a-descriptions-item label="项目类别">
                <a-row :gutter="16">
                  <a-col :span="8" v-for="item in financial_year" :key="item.name">{{ item.name }}年度</a-col>
                </a-row>
              </a-descriptions-item>
              <a-descriptions-item v-for="(row, row_idx) in financials" :key="row.financial_type_id" :label="row.name">
                <a-row :gutter="16">
                  <a-col :span="8" v-for="item in financial_year" :key="item.name">
                    <a-input style="border:none;" v-model:value="financials[row_idx][item.name]"
                      placeholder="请输入" suffix="万元"></a-input>
                  </a-col>
                </a-row>
              </a-descriptions-item>

            </a-descriptions>
          </a-form-item>
          <a-form-item class="selectFormItem" label="条件指标">
            <a-row style="margin-bottom:12px;" :gutter="16" v-for="(item, idx) in condition_indicator" :key="idx">
              <a-col :span="14">
                <a-tag closable @close="delCondSub(idx, tag.tag_id)" v-for="tag in item.list" :key="tag.tag_id">
                  {{ labels.find(f => f.value == tag.tag_id)?.label }} {{ tag.value }}</a-tag>
              </a-col>
              <a-col :span="3">
                <a-select optionFilterProp="label" showSearch :options="labels" v-model:value="item.newCond.tag_id"
                  placeholder="请选择">
                </a-select>
              </a-col>
              <a-col :span="3">
                <a-input
                  v-if="labels.find(f => f.value == item.newCond.tag_id)?.type == '2' || labels.find(f => f.value == item.newCond.tag_id)?.type == '3'"
                  v-model:value="item.newCond.value" placeholder="请输入标签值"></a-input>
              </a-col>
              <a-col :span="4">
                <a-button type="info" style="margin-right:12px;" @click="addConditionIndicatorSub(idx)">添加标签</a-button>
                <a-button type="danger" @click="delCond(idx)">删除</a-button>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="8">
                <a-button type="primary" @click="addConditionIndicator">添加指标</a-button>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item class="selectFormItem" label="必要指标">
            <a-row style="margin-bottom:12px;" :gutter="16" v-for="(item, idx) in require_indicator" :key="idx">
              <a-col :span="14">
                <a-tag closable @close="delRequireSub(idx, tag.tag_id)" v-for="tag in item.list" :key="tag.tag_id">
                  {{ labels.find(f => f.value == tag.tag_id)?.label }} {{ tag.value }}</a-tag>
              </a-col>
              <a-col :span="3">
                <a-select optionFilterProp="label" showSearch :options="labels" v-model:value="item.newCond.tag_id"
                  placeholder="请选择">
                </a-select>
              </a-col>
              <a-col :span="3">
                <a-input
                  v-if="labels.find(f => f.value == item.newCond.tag_id)?.type == '2' || labels.find(f => f.value == item.newCond.tag_id)?.type == '3'"
                  v-model:value="item.newCond.value" placeholder="请输入标签值"></a-input>
              </a-col>
              <a-col :span="4">
                <a-button v-if="!item.list.length" type="info" style="margin-right:12px;"
                  @click="addRequireIndicatorSub(idx)">添加标签</a-button>
                <a-button type="danger" @click="delRequire(idx)">删除</a-button>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="8">
                <a-button type="primary" @click="addRequireIndicator">添加指标</a-button>
              </a-col>
            </a-row>
          </a-form-item>
        </template>
      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
// import UploadFile from '@/components/common/uploadFile.vue';
import service from '@/service/service';
import { message } from 'ant-design-vue';
import options from '@/util/address-options-tree';
import dayjs from 'dayjs';


const rules = {
  zclx: [{
    required: true,
    message: '请选择政策类型',
  }],
};
export default {
  setup() {
    return {
      areas: [{
        label: '全国',
        value: '00',
        children: options,
      }],
    }
  },
  components: {
    // UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      condition_indicator: [],
      require_indicator: [],
      labels: [],
      qyfgCheckedKeys: [],
      form: {
        id: '',
        declare_item: '',
        amount: '',
        zclx: undefined,
        jzrq: '',
        ksrq: '',
        sbtj:'',
        fcbz:'',
        sbcl:'',
        description:'',
        ctype:undefined,
        // hy: [],
        title: '',
        dept_info:[],
        area_code: '',
        release_time: '',
        trade: [],
        item: undefined,
      },
      // hyTreeData: [],
      sbxms: [],
      items: [],
      ctypes:[],
      trades: [],
      tagData: [],
      visible: false,
      createLoading: false,
      financial_year: [],
      financials: [],
    }
  },
  watch: {
    current: {
      handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          for (let key in this.form) {
            let tempVal = (['declare_item', 'item','ctype', 'zclx'].includes(key) ? (nowCurrent[key] || undefined) : (nowCurrent[key] || ''));
            temp[key] = tempVal;
            if (key == 'trade') {
              temp[key] = nowCurrent[key] ? nowCurrent[key].split(',').map(m => Number(m)) : [];
            }

            if (key == 'ctype') {
              temp[key] = nowCurrent[key] ? nowCurrent[key].split(',').map(m => String(m)) : [];
            }

          }
          if (nowCurrent['area_codes']) {
            this.qyfgCheckedKeys = nowCurrent['area_codes'].split(',');
          }
          console.log(temp)
          this.form = temp;
          this.getIndicator(nowCurrent.id);
        }
      },
      immediate: true
    }
  },
  methods: {
    filterTreeOption (input, treeNode) {
        return treeNode.data.props.title.includes(input)
      },

    addConditionIndicator() {
      this.condition_indicator.push({
        newCond: {
          tag_id: undefined,
          value: '',
        },
        list: []
      });
    },
    delCond(idx) {
      this.condition_indicator = this.condition_indicator.filter((f, f_idx) => f_idx != idx);
    },
    delCondSub(idx, tag_id) {
      let currentCond = this.condition_indicator[idx];
      currentCond.list = currentCond.list.filter((f) => f.tag_id != tag_id);
      this.condition_indicator = [...this.condition_indicator];
    },
    addConditionIndicatorSub(idx) {
      let currentCond = this.condition_indicator[idx];
      if (!currentCond.newCond.tag_id) {
        message.error('请选择标签');
        return;
      }
      currentCond.list.push({ ...currentCond.newCond });
      currentCond.newCond = {
        tag_id: undefined,
        value: '',
      };
      this.condition_indicator = [...this.condition_indicator];
    },

    addRequireIndicator() {
      this.require_indicator.push({
        newCond: {
          tag_id: undefined,
          value: '',
        },
        list: []
      });
    },
    delRequire(idx) {
      this.require_indicator = this.require_indicator.filter((f, f_idx) => f_idx != idx);
    },
    delRequireSub(idx, tag_id) {
      let currentCond = this.require_indicator[idx];
      currentCond.list = currentCond.list.filter((f) => f.tag_id != tag_id);
      this.require_indicator = [...this.require_indicator];
    },
    addRequireIndicatorSub(idx) {
      let currentCond = this.require_indicator[idx];
      if (!currentCond.newCond.tag_id) {
        message.error('请选择标签');
        return;
      }
      currentCond.list.push({ ...currentCond.newCond });
      currentCond.newCond = {
        tag_id: undefined,
        value: '',
      };
      this.require_indicator = [...this.require_indicator];
    },

    disabledDate(current) {
      return current && current < dayjs().subtract(1, 'day').endOf('day');
    },
    async getIndicator(id) {
      let res = await service.get_indicator_tag({ id: id })
      // console.log(res);
      this.condition_indicator = res.data.condition_indicator.map(m => ({
        list: m.tag_data,
        newCond: {
          tag_id: undefined,
          value: '',
        },
      }))
      this.require_indicator = res.data.require_indicator.map(m => ({
        list: m.tag_data,
        newCond: {
          tag_id: undefined,
          value: '',
        },
      }))
    },
    async getOptions() {
      // let hyRes = await service.get_industry_lists();
      // this.hyTreeData = hyRes?.data || [];
      let labels = await service.get_tag_lists();
      this.labels = labels?.data.map(m => ({ type: m.type, value: m.id + '', label: m.name }));

      let items = await service.get_item_lists();
      this.items = items?.data.map(m => ({ value: m.id + '', label: m.name })) || [];

      let ctypes = await service.get_ctype_lists();
      this.ctypes = ctypes?.data.map(m => ({ value: m.id + '', label: m.name })) || [];

      let trades = await service.get_trade_lists();
      this.trades = trades?.data || [];

      let sbxms = await service.get_declare_lists();
      this.sbxms = sbxms?.data.map(m => ({ value: m.id + '', label: m.name })) || [];

      let tagRes = await service.get_article_tag({
        id: this.id || this.form.id
      });
      tagRes.data = tagRes.data.map(m => ({
        ...m,
        tag_data: m.tag_data.map(m => ({
          ...m,
          value: m.value || '',
          selected: m.selected || false,
        }))
      }));
      this.tagData = tagRes.data;

      // 获取财务表格
      let financial_year = await service.get_setting_info({ key: 'financial_year' });
      let financials = await service.get_article_financial({ id: this.form.id });
      this.financial_year = financial_year.data?.setting_option || [];
      this.financials = financials.data;
    },
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },

    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    onShow() {
      this.visible = true;
      this.getOptions();
    },
    handleInput(tag, e) {
      if (e.target.value) {
        tag.selected = true;
      }
      else {
        tag.selected = false;
      }
    },
    async onConfirm() {
      if (!this.form.zclx) {
        message.error('请输入政策类型');
        return;
      }

      
      let area_codes = '';
      if (this.qyfgCheckedKeys.length) {
        area_codes = this.qyfgCheckedKeys.join(',');
      }
      this.createLoading = true;

      if (this.financials) {
        let fi_s = [];
        this.financials.forEach(m => {
          fi_s = fi_s.concat(this.financial_year.map(year => ({
            financial_type_id: m.financial_type_id,
            name: year.name,
            value: m[year.name]
          })));
        });
        await service.set_article_financial({
          id: this.form.id,
          data: fi_s
        });
      }

      const api = 'set_article_tag';
      let tag_data = [];
      this.tagData.forEach(f => {
        f.tag_data.filter(filter => filter.selected).forEach(tag => {
          tag_data.push({
            tag_id: tag.id,
            value: tag.value
          })
        })
      });
      const { code, msg } = await service[api]({
        ...this.form,
        tag_data,
        area_codes,
        trade: this.form.trade?.join(',') || '',
        condition_indicator: this.condition_indicator.map(m => m.list),
        require_indicator: this.require_indicator.map(m => m.list)
        // trade: '',
      })
      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  }
}
</script>

<style lang="less">
.selectFormItem {
  .ant-form-item-label {
    label {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      padding: 0 6px;
      background: #3399ff;
      line-height: 32px;
      border-radius: 3px;
    }
  }

  .customDescript {
    .ant-descriptions-item-content {
      height: 100%;

      >span {
        height: 100%;
        display: block;
      }

      .ant-row {
        .ant-col {
          height: 100%;
          // text-align: center;
          border-right: 1px solid #f0f0f0;

          &:last-child {
            border: none;
          }
        }
      }

    }
  }
}
</style>
<style lang="less" scoped>
.checkbox-wrap {
  max-height: 32px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 12px;
}
</style>