<template>
  <div>
    <a-drawer :title="`${form.id ? '上传' : '添加'}政策`" :width="720" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="政策标题" name="declare_item">
              <a-input v-model:value="form.title" placeholder="请输入">
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="发布日期" name="declare_item">
              <a-date-picker v-model:value="form.release_time" style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="来源部门" name="declare_item">
              <a-select :options="depts" v-model:value="form.dept_id" showSearch="true" placeholder="请选择">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="政策文件" name="icon">
              <UploadFile v-model:value="form.file" />
            </a-form-item>
          </a-col>

          <!-- <a-col :span="12">
            <a-form-item label="分类说明" name="description">
              <a-input v-model:value="form.description" placeholder="请输入" />
            </a-form-item>
          </a-col> -->
        </a-row>
      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
//

import service from '@/service/service';
import UploadFile from '@/components/common/uploadFile.vue';
import { message } from 'ant-design-vue';
const rules = {
  
};
export default {
  components: {
     UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      form: {
        id: '',
        invoice: '',
        
      },
      configData:{},//支付配置参数
      visible: false,
      createLoading: false,
      depts:[]
    }
  },
  watch: {
    current: {
      handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          for (let key in this.form) {
            temp[key] = nowCurrent[key];
          }
          this.form = temp;
          // console.log("bbbbbb")
          // console.log(this.configData)
        }
      },
      immediate: true
    }
  },
  methods: {
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },
    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    onShow() {
      this.visible = true;
      this.getOptions();
    },
    async getOptions() {
      // let hyRes = await service.get_industry_lists();

      let depts = await service.get_dept_lists();
      this.depts = depts?.data.map(m => ({ value: m.dept_id + '', label: m.dept_name })) || [];

  
    },
    async onConfirm() {
      this.createLoading = true;
      console.log(this.form);
      this.form.config = this.configData;

      const api = this.form.id ? 'edit_article' : 'add_article';
      const { code, msg } = await service[api]({
        ...this.form
      })
      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  }
}
</script>